import ResizeDisk from '@/layouts/Stack/components/ResizeDisk';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, modals],
  computed: {
    quota() {
      return this.$store.getters['moduleStack/aviableSpaseVolume'];
    },
    projectId() {
      return this.$store.getters['moduleStack/projectId'];
    },
    id() {
      return this.tariff.id;
    },
    bootable() {
      return this.tariff.bootable === 'true';
    },
    type() {
      return this.tariff.volume_type;
    },
  },
  methods: {
    resizeDisk: function (size, project, disk) {
      const that = this;
      return new Promise(() => {
        const selfName = 'ResizeDisk';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: ResizeDisk,
          closeOnBackdrop: false,
          props: {
            bootable: this.bootable,
            quota: this.quota,
            // size: size + 1,
            size: size,
            type: this.type,
          },
          text: this.$t('sure.text'),
          on: {
            size: data => {
              size = data;
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.sendResizeDisk(project, disk, size).then(data => {
                    if (data.status === 202) {
                      this.$modals.close({ name: selfName });
                      const payload = { project: this.projectId, disk: this.id };
                      this.$store
                        .dispatch('moduleStack/fetchQuotasVolume')
                        .catch(e => console.log(e));
                      this.$store
                        .dispatch('moduleStack/checkDiskStatus', payload)
                        .then(async data => {
                          const res = await data.status;
                          if (res !== 'in-use') {
                            return this.updateDiskStatus(_, {
                              project: this.projectId,
                              disk: this.id,
                              type: 'volumes',
                              silent: true,
                            });
                          }
                        })
                        .catch(e => this.showError(e));
                      // });
                      // setTimeout(() => this.openModalsCode(code, phone, route), 200);
                      this.showResModal(
                        'Размер диска успешно увеличен, дождитесь изменения статуса.'
                      );
                    }
                  });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async updateDiskStatus(callback, payload) {
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkDiskStatus', {
            project: this.projectId,
            disk: this.id,
            type: 'volumes',
            silent: true,
          })
          .then(async data => {
            if (['extending'].includes(data.status)) {
              this.res = data.status;
              await this.updateDiskStatus(callback, payload);
            } else {
              // console.log(data);
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data.status;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    sendResizeDisk(project, disk, size) {
      const params = {
        project: project,
        disk: disk,
        size: size,
      };
      // console.log(params);
      return this.$store.dispatch('moduleStack/resizeDisk', params).catch(e => {
        // console.log(e);
        this.showError(e);
      });
    },
    // sendCode(code) {
    //   return this.$store.dispatch('moduleProfile/sendCode', code).catch(e => this.showError(e));
    // },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     cancel: {
    //       props: { title: this.$t('close') },
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
