<template>
  <div class="resize">
    <!--    <div class="resize-row">{{ $t('attach') }}</div>-->
    <label class="resize-row standart-text">{{ $t('attach') }}</label>
    <div class="l-col">
      <base-select
        v-model="instance"
        :hide-selected="true"
        select-label=""
        :searchable="false"
        :options="options"
        size="medium"
        :allow-empty="false"
        :preselect-first="true"
        class="resize-row-item"
        @input="onChange"
      >
      </base-select>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
export default {
  name: 'AttachDisk',
  components: { BaseSelect },
  mixins: [setFocus],
  data() {
    return {
      instance: [],
    };
  },
  computed: {
    options() {
      return this.$store.getters['moduleStack/servers'].map(x => `${x.name} (${x.id})`);
    },
  },
  methods: {
    onChange(event) {
      this.$emit('instance', event.match(/\((.+?)\)/)[1]);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Подключить к инстансу",
    "sure": {
    "confirm": "Подключить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
