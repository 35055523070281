<template>
  <div class="resize">
    <div class="resize-row">
      <!--      <div v-if="isMaxSize">-->
      <!--        <label>{{ alarmText }}</label>-->
      <!--      </div>-->
      <div class="resize-row-text">
        <label class="standart-text"> Увеличить размер диска </label>
      </div>
      <div class="resize-row-input">
        <!--          v-tooltip="'Enter your username'"-->
        <!--          :max="quota + size > 2048 ? 2048 : quota + size"-->
        <InputNumber
          id="value"
          v-model="value"
          show-buttons
          mode="decimal"
          suffix=" ГБ"
          :min="size + 1"
          :max="isBootable"
          button-layout="horizontal"
          :input-style="{ 'text-align': 'center', 'max-width': '5rem' }"
          decrement-button-class="p-button-blue"
          increment-button-class="p-button-blue"
          class="p-inputtext-sm"
          increment-button-icon="pi pi-plus"
          decrement-button-icon="pi pi-minus"
          aria-describedby="value2-help"
          @input="onChange"
        />
      </div>
    </div>
    <div class="resize-row">
      <div v-if="value + 1 < size || value > quota" :style="{ 'text-align': 'center' }">
        <!--          value < size + quota ? $t('quotaDown') : $t('quotaUp')-->
        <label class="typo__label section-header standart-text error-color">{{ alarmText }}</label>
      </div>
      <div v-else>
        <label class="standart-text"> Вам доступно {{ aviableForUse }} ГБ </label>
      </div>
    </div>
    <div class="resize-row">
      <label class="resize-row__sum standart-text">
        Стоимость увеличится на {{ $n(difference * period, 'currency') }} за
      </label>
      <base-select
        :value="periodValue"
        :options="priceByPeriod"
        :searchable="false"
        :allow-empty="false"
        label="label"
        :show-labels="false"
        class="resize-row__period"
        @input="onPeriodChange"
      />
    </div>
    <div class="resize-row">
      <label class="standart-text">{{ $t('text') }}</label>
    </div>
    <div class="resize-row">
      <label class="standart-text">{{ $t('notice') }}</label>
    </div>
    <div v-if="isMaxSize">
      <div class="resize-row">
        <label class="typo__label medium-title error-color"
          ><label class="standart-text">{{ $t('size1') }}</label></label
        >
      </div>
      <div class="resize-row">
        <label class="typo__label medium-title error-color"
          ><label class="standart-text">{{ $t('size2') }}</label></label
        >
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import InputNumber from 'primevue/inputnumber';
import BaseSelect from '@/components/Select/BaseSelect';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'ResizeDisk',
  components: { BaseSelect, InputNumber },
  mixins: [setFocus],
  props: {
    quota: {
      type: Number,
      default: 0,
      require: true,
    },
    size: {
      type: Number,
      default: 0,
      require: true,
    },
    bootable: {
      type: Boolean,
      // default: 0,
      require: true,
    },
    type: {
      type: String,
      required: true,
      validator: val => ['HDD', 'SSD', 'SSD-Lite'].includes(val),
    },
  },
  data() {
    return {
      value: this.size + 1,
      period: 1,
    };
  },
  computed: {
    // dev() {
    //   return IS_PROD ? 'stat' : 'full_price';
    // },
    isBootable() {
      if (this.bootable) {
        return this.quota + this.size > 2048 ? 2048 : this.quota + this.size;
      } else {
        return this.quota + this.size;
      }
    },
    isMaxSize() {
      return this.value >= 2048;
    },
    aviableForUse() {
      return this.value - this.size < this.size + this.quota
        ? this.quota - this.value + this.size
        : this.value > this.size + this.quota
        ? 0
        : this.quota - this.value;
    },
    alarmText() {
      if (this.value + 1 < this.size) {
        return this.$t('quotaDown');
      } else if (this.value > this.size + this.quota) {
        return this.$t('quotaUp');
      } else return '';
    },
    priceByPeriod() {
      return [
        {
          label: this.$t('hour'),
          value: 1,
        },
        {
          label: this.$t('day'),
          value: 24,
        },
        {
          label: this.$t('month'),
          value: 24 * 30,
        },
      ];
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === +this.period);
    },
    periodLabel() {
      return this.priceByPeriod.find(i => i.value === this.period).label.toLowerCase();
    },
    cost() {
      return this.$store.state.moduleStack.price.find(x => x.intname === `volume_${this.type}`)[
        dev
      ];
    },
    difference() {
      return (this.value - this.size) * this.cost;
      // return this.value < this.size
      //   ? 0
      //   : this.value + this.size > this.quota
      //   ? this.quota * this.cost
      //   : (this.value - this.size) * this.cost;
    },
  },
  methods: {
    onChange(event) {
      // console.log(event);
      this.$emit('size', event);
    },
    onPeriodChange(obj) {
      this.period = obj.value;
      // this.calcSum();
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "ticket": {
      "label": "Подтверждение телефона"
    },
    "day": "День",
    "hour": "Час",
    "month": "Месяц",
    "quotaUp": "Значение превышает максимально разрешенное",
    "quotaDown": "Уменьшать диск нельзя",
    "sure": {
    "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска.",
    "notice": "Для вступления в силу - перезагрузите сервер.",
    "size1": "Диски в формате mbr не могут быть больше 2ТБ.",
    "size2": "Диски больше 2Тб должны быть размечены GPT."
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 1rem;

    &__period {
      margin-left: 1rem;
    }
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
