/* eslint-disable vue/no-v-html */
<template>
  <div class="resize">
    <div class="l-col">
      <label class="typo__label small-title">{{ $t('disk.label') }}</label>
      <label class="typo__label big-title">{{ ' ' + name }}</label>
    </div>
    <div v-if="snapshots && snapshots.length > 0" class="l-col">
      <p>
        <label class="typo__label small-title underline">{{ $t('disk.encore') }}</label>
      </p>
      <div v-if="snapshots.length">
        <div v-for="snap in snapshots" :key="snap.id">
          <!--          <label class="typo__label medium-title">{{ // 'снапшот - ' + ip.name }}</label>-->
          <div class="l-col">
            <label class="typo__label medium-title">{{
              `${snap.name} от ${dateSnapshots(snap)}`
            }}</label>
          </div>
        </div>
      </div>
      <div>
        <!--        <label class="typo__label medium-title">{{}}</label>-->
      </div>
    </div>
    <div class="l-col medium-text">
      <p v-html="summaryCost"></p>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import { format, parseISO } from 'date-fns';
export default {
  name: 'DeleteDisk',
  components: {},
  mixins: [setFocus],
  props: {
    name: {
      type: String,
      default: '',
    },
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      shared: [],
      private: [],
      float: [],
      fixed: [],
      sharedIp: [],
    };
  },
  computed: {
    summaryCost() {
      const cost =
        this.snapshots.length === 0
          ? this.cost(`volume_${this.instance.volume_type}`)
          : this.cost(`volume_${this.instance.volume_type}`) + this.snapExpenses;
      return this.$t('cost', {
        cost: this.$n(cost, 'currency', this.$i18n.locale),
      });
    },
    snapshots() {
      return this.$store.state.moduleStack.snapshots.filter(x => x.volume_id === this.instance.id);
      // .map(i => i.id) || []
    },
    snapExpenses() {
      let sum = 0;
      this.snapshots.forEach(x => {
        // sum += x.size * this.cost(`snapshot_${this.instance.volume_type}`);
        sum += this.cost(`snapshot_${this.instance.volume_type}`);
        // console.log(sum, x.size, this.cost(`snapshot_${this.instance.volume_type}`));
      });
      return sum;
    },

    // disks() {
    //   return this.instance['os-extended-volumes:volumes_attached'].length
    //     ? this.instance['os-extended-volumes:volumes_attached'].map(id => {
    //         return {
    //           name:
    //             this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id).name ||
    //             this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id).id,
    //           size: this.$store.state.moduleStack.volumes.find(disk => disk.id === id.id).size,
    //           deletable: id.delete_on_termination,
    //         };
    //       })
    //     : [];
    // },
    // ports() {
    //   return this.$store.state.moduleStack.ports.filter(
    //     port => port.device_id === this.instance.id
    //   );
    // },
    // isDeleteDisk() {
    //   return this.disks && this.disks.length > 0 ? this.disks.filter(x => x.deletable) : [];
    // },
    // isUndeleteDisk() {
    //   return this.disks && this.disks.length > 0 ? this.disks.filter(x => !x.deletable) : [];
    // },
    // publicNetwork() {
    //   return Object.keys(this.instance.addresses);
    // },
    // networks() {
    //   return this.$store.state.moduleStack.networks;
    // },
    // undelete() {
    //   return (
    //     (this.isUndeleteDisk && this.isUndeleteDisk.length > 0) ||
    //     (this.shared && this.shared.length > 0) ||
    //     (this.float && this.float.length > 0)
    //   );
    // },
    // port()
  },
  mounted() {
    // this.generatePass();
    this.init();
  },
  methods: {
    price(name) {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === name)[dev]
        : null;
    },
    dateSnapshots(snap) {
      return format(snap.created_at, 'dd.MM.yyyy HH:mm:ss');
    },
    cost(name) {
      // return this.price(name) ? 24 * 30 * this.price(name) * this.instance.size : 0;
      return this.price(name) ? this.price(name) * this.instance.size : 0;
    },
    init() {
      // const net = Object.keys(this.instance.addresses);
      // net.forEach(x => {
      //   this.$store.state.moduleStack.networks.find(network => network.name === x).shared
      //     ? this.shared.push({
      //         name: x,
      //       })
      //     : this.private.push({
      //         name: x,
      //       });
      // });
      // if (this.shared.length) {
      //   // console.log(this.shared[0].name);
      //   this.instance.addresses[this.shared[0].name].forEach(x => {
      //     // console.log(x);
      //     // console.log('shared');
      //     this.sharedIp.push({
      //       ip: x.addr,
      //     });
      //   });
      // }
      // const float = [];
      // if (this.instance.addresses && this.private)
      //   Object.keys(this.instance.addresses).forEach(x => {
      //     this.private.find(net => {
      //       if (net.name === x) {
      //         this.instance.addresses[x].forEach(addr => {
      //           addr['OS-EXT-IPS:type'] === 'floating'
      //             ? float.push({
      //                 ip: addr.addr,
      //               })
      //             : null;
      //         });
      //       }
      //     });
      //   });
      // this.float = float;
      // const fixed = [];
      // if (this.instance.addresses && this.private)
      //   Object.keys(this.instance.addresses).forEach(x => {
      //     this.private.find(net => {
      //       if (net.name === x) {
      //         this.instance.addresses[x].forEach(addr => {
      //           if (addr['OS-EXT-IPS:type'] === 'fixed') {
      //             fixed.push({
      //               ip: addr.addr,
      //             });
      // console.log()
      // this.$emit('change', addr);
      // }
      // addr['OS-EXT-IPS:type'] === 'fixed'
      //   ? fixed.push({
      //       ip: addr.addr,
      //     })
      //   : null;
      // });
      // }
      // });
      // });
      // this.fixed = fixed;
    },
  },
};
</script>

<i18n>
{
  "ru": {

    "cost": "При этом ваши расходы уменьшатся на <b>%{cost}</b> в час.",
    "disk": {
      "label": "Вы собираетесь удалить диск:",
      "encore": "Вместе с диском удалится:",
      "undelete": "Останутся без изменения:",
      "description" : "Текстовое описание"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
  .underline {
    text-decoration underline red;
    //color: $error
  }
</style>
