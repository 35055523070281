import DeleteDisk from '@/layouts/Stack/components/DeleteDisk';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  props: {
    tariff: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: {},
      isProcessing: false,
    };
  },
  computed: {
    // ports() {
    //   return this.$store.state.moduleStack.ports
    //     .filter(port => port.device_id === this.tariff.id)
    //     .map(id => id.id);
    // },
  },
  methods: {
    deleteDisk: function (instance) {
      const that = this;
      let res = '';
      let self;
      let time;
      return new Promise(() => {
        const selfName = 'deleteDisk';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: DeleteDisk,
          closeOnBackdrop: false,
          props: {
            name: instance.name,
            instance: instance,
          },
          on: {
            change: data => {
              instance = data;
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen(inst) {
            self = inst;
          },
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.delete'), color: 'error' },
              on: {
                click: () => {
                  self.text = null;
                  self.component = BaseLoader;
                  self.closable = false;
                  self.footer = false;
                  time = Date.now();
                  this.isProcessing = true;
                  // console.log(instance);
                  if (this.snapshotsId.length > 0) {
                    // console.log(this.snapshotsId.length);
                    this.snapshotsId.map(
                      async (snap, i) =>
                        await this.deleteSnapshot(snap)
                          .then(async () => {
                            const payload = { disk: this.id };
                            await this.$store
                              .dispatch('moduleStack/checkDiskStatus', payload)
                              .then(async data => {
                                const res = await data.status;
                                // console.log(res);
                                if (res !== 'available') {
                                  return this.updateDiskStatusD(_, {
                                    disk: this.id,
                                    type: 'volumes',
                                    silent: true,
                                  });
                                } else return res;
                              })
                              .catch(e => this.showError(e));

                            // res = 'success';
                          })
                          .then(async () => {
                            // console.log(i);
                            if (i === this.snapshotsId.length - 1) {
                              setTimeout(async () => {
                                await this.deleteCurrentDisk(this.id).then(async data => {
                                  // console.log(data);
                                });
                              }, 3000);
                              res = 'success';
                              return res;
                            }
                          })
                    );
                  } else {
                    this.deleteCurrentDisk(instance.id).then(data => {
                      if (data === 202) {
                        res = 'delete';
                      } else res = 'fail';
                    });
                  }
                  // .catch(() => (res = 'fail'))
                  // .finally(() => {
                  const delay = Date.now() - time < 1000 ? 1000 : 0;
                  setTimeout(() => {
                    self.component = null;
                    self.closable = true;
                    self.text = this.$t(`modal.sure.${res}`);
                    self.footer = {
                      centered: true,
                      confirm: {
                        props: { title: this.$t('modal.sure.close') },
                        on: {
                          click: () => {
                            this.$modals.close();

                            this.fetchServer();
                            this.showResModal('Диск успешно удалён.');
                            this.$router
                              .push({
                                name: 'containerDisk',
                              })
                              .catch(err => {
                                if (
                                  err.name !== 'NavigationDuplicated' &&
                                  !err.message.includes(
                                    'Avoided redundant navigation to current location'
                                  )
                                ) {
                                  this.showErrorModal(err);
                                }
                              });
                          },
                        },
                      },
                    };
                  }, delay);
                  // });
                },
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        });
      });
    },
    sendDiskAction(params) {
      return this.$store.dispatch('moduleStack/setDiskAction', {
        id: this.tariff.id,
        params,
      });
    },
    // deleteCurrentDisk() {
    //   return this.$store.dispatch('moduleStack/deleteDisk', this.tariff.id);
    // },
    // async deleteCurrentPort(port) {
    //   return this.$store.dispatch('moduleStack/deletePort', {
    //     port: port,
    //   });
    // },
    async updateDiskStatusD(callback, payload) {
      this.timerCnt = 1;
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkDiskStatus', {
            disk: this.id,
            type: 'volumes',
            silent: true,
          })
          .then(async data => {
            console.log(data.status);
            if (!['available'].includes(data.status)) {
              this.res = data.status;
              await this.updateDiskStatusD(callback, payload);
            } else {
              this.timerCnt = 1;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data.status;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    // async deleteAllPorts() {
    //   return await this.ports.forEach(port => {
    //     this.deleteCurrentPort(port)
    //       .then(data => {
    //         console.log(data);
    //       })
    //       .catch(e => console.log(e));
    //   });
    // },
    deleteSnapshot(snap) {
      return this.$store.dispatch('moduleStack/deleteSnapshot', {
        snap: snap,
      });
    },
    deleteCurrentDisk(id) {
      console.log(id);
      return this.$store.dispatch('moduleStack/deleteDisk', {
        disk: id,
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
