export default {
  computed: {
    list() {
      return this.$store.state.moduleStack.list;
    },
    servers() {
      return this.$store.state.moduleStack.servers;
    },
    disks() {
      return this.$store.state.moduleStack.volumes;
    },
    flavors() {
      return this.$store.state.moduleStack.flavors;
    },
    networks() {
      return this.$store.state.moduleStack.networks;
    },
    subnets() {
      return this.$store.state.moduleStack.subnets;
    },
  },
  methods: {
    newFetch() {
      this.isRequest = true;
      this.$store.state.moduleStack.isLoading = true;
      this.$store
        .dispatch('moduleStack/fetchOpenStackRequest', 'servers')
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'floatingips');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'volumes');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'flavors');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'images');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'routers');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'ports');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'subnets');
        })
        // .then(async () => {
        //   const payload = [this.servers, this.disks, 'disk'];
        //   await this.$store.dispatch('moduleStack/updateDiskServers', payload);
        // })
        // .then(async () => {
        //   const payload = [this.servers, this.flavors, 'flavors'];
        //   await this.$store.dispatch('moduleStack/updateFlavorsServers', payload);
        // })
        // .then(async () => {
        //   const payload = [this.servers, this.networks, 'networks'];
        //   await this.$store.dispatch('moduleStack/updateNetworksServers', payload);
        // })
        .catch(e => {
          console.error(e);
          this.isRequest = false;
          this.$store.state.moduleStack.isLoading = false;
        })
        .finally(() => {
          this.isRequest = false;
          this.$store.state.moduleStack.isLoading = false;
        });
    },
  },
};
