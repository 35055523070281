import ChangeDescription from '@/layouts/Stack/components/ChangeDescription';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';

export default {
  mixins: [showErrorModal, storeMixin],
  data() {
    return {
      data: {},
      description: '',
      isProcessing: false,
    };
  },
  methods: {
    changeDiskDescription: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'changePassword';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: ChangeDescription,
          closeOnBackdrop: false,
          props: {
            description: instance.description,
            text: this.$t('description.name'),
          },
          on: {
            change: data => {
              this.description = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('description.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  const params = {
                    params: {
                      volume: { description: this.description.trim().replaceAll('\n', ' ') },
                    },
                    type: 'description',
                    id: this.id,
                  };
                  this.updateDisk(params)
                    .then(data => {
                      if (data) {
                        that.$modals.close();
                        this.showResModal('Описание диска изменено.');
                      }
                    })
                    .catch(e => {
                      that.$modals.close();
                      // console.log(e.response.data.badRequest.message);
                      this.showError(e.response.data.badRequest.message);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    updateDisk(params) {
      return this.$store.dispatch('moduleStack/renameDisk', params);
    },
    makeModal() {
      this.$modals.open({
        name: 'AddModal',
        html: true,
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        confirm: {
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
